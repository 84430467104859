import React, { useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Reveal from '../../components/Reveal';
import Verified from '../../layouts/images/verification.gif';

function SendFormMobileApp() {
    const navigate = useNavigate()
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isCenterVisible, setIsCenterVisible] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");
    const [isPanding, setIsPanding] = useState(false)
  
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
    });
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const [errorFields, setErrorFields] = useState({
      name: false,
      email: false,
      message: false,
    });
  
    const handleSubmit = (event) => {
      event.preventDefault();
        
      if (
        !formData.name ||
        !formData.email ||
        !formData.message
      ) {
        console.log("Please fill in all the fields with valid data");
    
        setErrorFields({
          name: !formData.name,
          email: !formData.email,
          message: !formData.message,
        });
    
      } else {
        setFormSubmitted(true);
        setIsCenterVisible(false);
    
        fetch('http://api.easygo.uz/api/wishes', {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData)
        }).then((response) => {
            console.log(formData);
          setIsPanding(false);
          return response.json();
        }).catch((error) => {
          setIsPanding(false);
          console.error('Error:', error);
        });
      }
    };
    
  
    const handleOkButtonClick = () => {
      setFormSubmitted(false);
      setIsCenterVisible(true);
    };  
  
    const handleVerification = () => {
      setIsVerified(true);
      setTimeout(() => {
        setIsVerified(false);
      }, 2000);
    };

    const handleSubmitBackend = (e) => {
        e.preventDefault();
        const data = { name, email, text };
      
        setIsPanding(true);
      }

    return (
        <>
            <div style={{ padding: '10px', marginLeft: '-20px' }}>
                <div className='d-flex mb-5'>
                    <button onClick={() => navigate(-1)} style={{ fontFamily: 'Jaldi', background: 'none', border: 'none', fontSize: '50px', marginTop: '-18px' }}>
                        <div style={{ width: '20px', height: '20px' }} onClick={() => navigate(-1)} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width={30} height={30} viewBox="0 0 256 256" xmlSpace="preserve">
                            {/* <defs></defs>
                            <g style={{ stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1 }} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                <path
                                    d="M 65.75 90 c 0.896 0 1.792 -0.342 2.475 -1.025 c 1.367 -1.366 1.367 -3.583 0 -4.949 L 29.2 45 L 68.225 5.975 c 1.367 -1.367 1.367 -3.583 0 -4.95 c -1.367 -1.366 -3.583 -1.366 -4.95 0 l -41.5 41.5 c -1.367 1.366 -1.367 3.583 0 4.949 l 41.5 41.5 C 63.958 89.658 64.854 90 65.75 90 z"
                                    style={{ stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1 }}
                                    transform=" matrix(1 0 0 1 0 0) "
                                    strokeLinecap="round"
                                />
                            </g> */}
                        </div>
                    </button>
                    <p className='headerText'>Пожелания по улучшению сервиса</p>
                </div>
            </div>

            <div style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', width: '100%', height: '100%', background: 'rgb(223 223 223 / 54%)', marginTop: '-50px', padding: '20px' }}>
                <h3>Напишите сообщение</h3>
                <p>Заполните поля формы, и мы свяжемся с вами. <br /> Пожалуйста, не дублируйте письма.</p>

                {formSubmitted ? (
                    <center>
                        <div style={{paddingBottom: '20px', paddingTop: '20px', marginLeft: '-20px'}}>
                            <center>
                            <div className="card2 p-3" style={{width: '100%', padding: '20px 30px', marginLeft: '0px'}}>
                                <div className='ms-4'>
                                    <Reveal>
                                        <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                                            <h3 style={{color: 'black', fontSize: '30px', fontFamily: 'Heebo'}}>Отправлено</h3>
                                        </div>
                                    </Reveal>

                                    <Reveal>
                                        <div className={`verification-icon ${isVerified ? 'verified' : ''}`} onClick={handleVerification}>
                                            <img style={{width: '150px', height: '150px'}} src={Verified} alt="Verified" />
                                        </div>
                                    </Reveal>

                                    <Reveal>
                                        <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                                            <p style={{color: 'black', fontSize: '10px', fontFamily: 'Heebo'}}>Ваше мнения и предложения очень важны для нас. <br /> Оставьте свои комментарии и мы обязательно учтем все.</p>
                                        </div>
                                    </Reveal>
                                </div>
                                
                                <Reveal>
                                    <div>
                                        <input type="button" className="input_contact-btn" value='Ок ' onClick={handleOkButtonClick} />
                                    </div>
                                </Reveal>
                            </div>
                            </center>
                        </div>
                    </center>
                ) : (
                    <center>
                        {isCenterVisible && (
                            <div style={{paddingBottom: '20px', paddingTop: '20px', marginLeft: '-20px'}}>
                                <center>
                                    <div className="card2 p-3" style={{width: '100%', padding: '20px 30px'}}>
                                    <Reveal>
                                        <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                                            <h3 style={{color: 'black', fontSize: '30px', fontFamily: 'Heebo', }}>Обратная связь</h3>
                                        </div>
                                    </Reveal>

                                    <form style={{width: '100%'}} onSubmit={handleSubmitBackend} className='mt-4 mb-4'>
                                        <Reveal>
                                            <input
                                                type="text"
                                                className={`input_contact2 ${errorFields.name ? 'error' : ''}`}
                                                placeholder="Имя"
                                                name="name"
                                                required
                                                value={name}
                                                onChange={(e) => {
                                                    handleInputChange(e);
                                                    setName(e.target.value);
                                                }}
                                            />
                                        </Reveal>
                                        <Reveal>
                                        <input
                                            type="mail"
                                            className={`input_contact2 ${errorFields.phone ? 'error' : ''}`}
                                            placeholder="Email"
                                            name="email"
                                            required
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                        </Reveal>
                                        <Reveal>
                                        <textarea
                                            type="text"
                                            className={`input_contact_message2 ${errorFields.message ? 'error' : ''}`}
                                            placeholder="Текст сообщения"
                                            name="message"
                                            required
                                            value={text}
                                            onChange={(e) => {
                                            handleInputChange(e);
                                            setText(e.target.value);
                                            }}
                                        />
                                        </Reveal>
                                    </form>
                                        
                                    <div>
                                        { !isPanding && <input type="button" className="input_contact-btn2" value='Отправить' onClick={handleSubmit} />}
                                        { isPanding && <input type="button" className="input_contact-btn2" value='Отправка...' onClick={handleSubmit} />}
                                    </div>
                                    </div>
                                </center>
                            </div>
                        )}
                    </center>
                )}

                <p>Нажимая кнопку «Отправить», я соглашаюсь с тем, что мной прочитаны и приняты Пользовательское соглашение и Политика конфиденциальности.</p>
            </div>  
        </>
    )
}

export default SendFormMobileApp
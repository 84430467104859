import React from 'react'
import { useNavigate } from 'react-router-dom'

function PrivacyAgency() {
    const navigate = useNavigate()

    return (
        <div style={{ padding: '10px' }}>
            <div className='d-flex mb-5' style={{marginLeft: '50px'}}>

                <p className='headerText'>Политика конфиденциальности</p>
            </div>

            <p className='mobile_text p-1' style={{marginTop: '-60px', color: 'black', opacity: '1'}}>
                Дата публикации: __.__.2023г.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', marginTop: '-20px', fontSize: '18px'}}>
                Данное мобильное приложение далее Приложение, обслуживается и предоставляется ООО “DOCLINE“ далее Компания. Если Вы установите, и будете использовать данное Приложение, мы будем собирать данные о Вас. В настоящей Политике конфиденциальности приводятся сведения о том, как именно используются данные, собранные нашим Приложением, а также о том, куда Вы можете обратиться в случае каких- либо вопросов или сомнений.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                1. Персональная информация пользователей, которую получает и обрабатывает Приложение 
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  В рамках настоящей Политики под «персональной информацией пользователя» понимаются:
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Персональная информация, которую пользователь предоставляет о себе самостоятельно при регистрации (создании учётной записи) или в процессе использования приложения, включая персональные данные пользователя. Информация предоставляется пользователем на его усмотрение.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Данные, которые автоматически передаются Компании в процессе их использования с помощью, установленного на устройстве Приложения, в том числе информация из cookie, информация об устройстве пользователя.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Компания не проверяет достоверность персональной информации, предоставляемой пользователями, и не осуществляет контроль над их дееспособностью. Однако Компания исходит из того, что информация, переданная им от пользователей, является достоверной и поддерживает эту информацию в актуальном состоянии.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                2. Цели сбора и обработки персональной информации пользователей
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Приложение собирает и хранит только те персональные данные, которые необходимы для предоставления сервисов, входящих в состав приложения.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Персональную информацию пользователя Приложения Компания может использовать в следующих целях:
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Идентификация стороны в рамках соглашений;
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Предоставление пользователю персонализированных сервисов;
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Связь с пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования сервисов, оказания услуг, а также обработка запросов и заявок от пользователя;
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Улучшение качества Приложения, удобства его использования, разработка новых сервисов и услуг;
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Проведение статистических и иных исследований, на основе обезличенных данных.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                3. Условия обработки персональной информации пользователя и её передачи третьим лицам
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Компания обязуется осуществлять надлежащий контроль по учету и хранению конфиденциальности персональных данных Пользователя.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Компания обязуется соблюдать конфиденциальность в отношении персональных данных, а также иной информации, ставшей ей известной в связи с использованием Приложения, за исключением случаев, когда:
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  такая информация является общедоступной;
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  информация раскрыта по требованию или с разрешения Пользователя;
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  информация подлежит предоставлению контрагентам Пользователя в объеме, необходимом для исполнения условий Соглашения;
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  информация требует раскрытия по основаниям, предусмотренным законодательством, или по вызывающим подозрение сделкам, или при поступлении соответствующих запросов суда или уполномоченных государственных органов.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                При обработке персональных данных пользователей Приложения Компания руководствуется законом Республики Узбекистан «О персональных данных».
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                4. Меры, применяемые для защиты персональной информации Пользователя,
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Компания принимает необходимые и достаточные организационные и технические меры для защиты персональной информации пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                5. Данные о местоположении Пользователя.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Необходимо разрешить использовать данные о местоположении Пользователя, в том числе когда приложение свернуто
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  для точного расчета стоимости поездок
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  уведомления клиентов о местоположении водителя в процессе заказа
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  для сохранения истории выполненных заказов
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  для отображения карт и построения маршрутов.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                6. Разрешение доступа к фото и медиа-файлам Пользователя. Необходимо разрешить доступ к фото и медиа-файлам Пользователя,
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  для загрузки фотографии
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                6. Изменение Политики конфиденциальности. <br /> Применимое законодательство
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  Компания имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента её размещения, если иное не предусмотрено новой редакцией Политики.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                • &nbsp;  К настоящей Политике и отношениям между пользователем и Компанией, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Республики Узбекистан.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                8. Согласие на обработку персональных данных может быть отозвано субъектом персональных данных путем обращения посредством данных указанных в пункте 9.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                В случае отзыва субъектом персональных данных согласия на обработку персональных данных, Банк вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе Республики Узбекистан от 02.07.2019 ЗРУ №547 «О персональных данных» и Законом №439-II от 12.12.2002 РУз «О принципах и гарантиях свободы информации» в том числе для хранения персональных данных, предусмотренного законодательством РУз, в целях исполнения условий любого заключенного между Пользователем и компанией ООО «DOCLINE» договора или исполнения требований законодательства РУз,.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Приложение осуществляет обработку персональных данных Пользователей в течение всего срока действия соглашений, договоров, заключенных с ООО «DOCLINE», а также в течение 10 лет с даты прекращения обязательств сторон по соглашениям
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                9.Контактная информация
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                По общим вопросам обращайтесь в службу поддержки пользователей на номер: ____________, либо посредством электронной почты на адрес ________
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Юридический адрес: Республика Узбекистан, г.Ташкент, _________________
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Адрес электронной почты: ___________
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Телефон: ______________
            </p>
        </div>
    )
}

export default PrivacyAgency
import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import $ from 'jquery';
import '@splidejs/splide/dist/css/splide.min.css';
import Reveal from '../../components/Reveal';

// Images 
import Logo from '../../layouts/images/logo.svg';
import iPhone from '../../layouts/images/iphone.svg';
import AppStore from '../../layouts/images/appstore.svg';
import GooglePlay from '../../layouts/images/googleplay.svg';
import AppGallery from '../../layouts/images/appgalery.svg';
import AppStoreSvg from '../../layouts/images/appstore.svg';
import GooglePlaySvg from '../../layouts/images/googleplay.svg';
import AppGallerySvg from '../../layouts/images/appgalery.svg';
import Darts from '../../layouts/images/darts.svg';
import Lock from '../../layouts/images/lock.svg';
import Money from '../../layouts/images/money.png';
import Touch from '../../layouts/images/touch.png';
import Star from '../../layouts/images/star.png';
import Like from '../../layouts/images/like.svg';
import Call from '../../layouts/images/call.svg';
import Instagram from '../../layouts/images/instagram.svg';
import Facebook from '../../layouts/images/facebook.svg';
import Telegram from '../../layouts/images/telegram.svg';
import Verified from '../../layouts/images/verification.gif';
import FooterBg from '../../layouts/images/footer_bg.svg';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { NavLink } from 'react-router-dom';

function Home() {
  const [activeLink, setActiveLink] = useState('Главная');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isCenterVisible, setIsCenterVisible] = useState(true);
  const [data, setData] = useState([]);
  const containerRef = useRef(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [text, setText] = useState("");
  const [isPanding, setIsPanding] = useState(false)
  // var to_url = window.location;

  $(document).ready(function(){
    $(window).on('scroll', function(){
      if ($(window).scrollTop()) {
        $("header").addClass('bgc');
      }else{
        $("header").removeClass('bgc');
      }
    });
  });

  const handleNavClick = (link) => {
    setActiveLink(link);
  };

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: '',
    type: 1,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value, type: 1 });
  };

  const [errorFields, setErrorFields] = useState({
    name: false,
    phone: false,
    message: false,
    type: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const numericPattern = /^[0-9]+$/;
  
    if (
      !formData.name ||
      !formData.phone ||
      !formData.message ||
      !formData.type ||
      !numericPattern.test(formData.phone)
    ) {
      console.log("Please fill in all the fields with valid data");
  
      setErrorFields({
        name: !formData.name,
        phone: !formData.phone,
        message: !formData.message,
        type: !formData.type,
      });
  
    } else {
      setFormSubmitted(true);
      setIsCenterVisible(false);
  
      fetch('http://api.easygo.uz/api/feedback', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      }).then((response) => {
        setIsPanding(false);
        return response.json();
      }).catch((error) => {
        setIsPanding(false);
        console.error('Error:', error);
      });
    }
  };
  

  const handleOkButtonClick = () => {
    setFormSubmitted(false);
    setIsCenterVisible(true);
  };  

  const handleVerification = () => {
    setIsVerified(true);
    setTimeout(() => {
      setIsVerified(false);
    }, 2000);
  };

  const fetchData = () => {
    const fetchedData = [
      {
        image: Darts,
        text: (
          <p className='about-text' style={{color: 'black', width: '260px'}}>
            <b>Поиск автомобиля</b> <br /> на дальние расстояния (другие города, области);
          </p>
        ),
      },
      {
        image: Lock,
        text: (
          <p className='about-text' style={{color: 'black', width: '260px'}}>
            <b>Безопасность пользования <br /></b> — мы стараемся отслеживать все отзывы, оценки и профили всех ваших будущих попутчиков и, конечно же, водителя
          </p>
        ),
      },
      {
        image: Money,
        text: (
          <p className='about-text' style={{color: 'black', width: '260px'}}>
            <b>Поездка по низкой цене <br /></b> — мы ищем для вас подходящих попутчиков, соотвественно цена поездки становится ниже;
        </p>
        ),
      },
      {
        image: Touch,
        text: (
          <p className='about-text' style={{color: 'black', width: '260px'}}>
            <b>Комфортное пользование <br /></b> — заказ такси в пару кликов
          </p>
        ),
      },
      {
        image: Star,
        text: (
          <p className='about-text' style={{color: 'black', width: '260px'}}>
            <b>Удобный сервис <br /></b> — вы всегда можете обговорить с водителем цену, которая вам подходит
          </p>
        ),
      },
    ];

    const firstDataItem = fetchedData[0];
    fetchedData.push(firstDataItem);

    setData(fetchedData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    let interval;
  
    const handleScroll = () => {
      const container = containerRef.current;
      const scrollPosition = container.scrollLeft;
      const maxScroll = container.scrollWidth - container.clientWidth;
  
      if (scrollPosition >= maxScroll) {
        container.scrollLeft = 0;
      }
    };

    window.addEventListener('scroll', () => {
      const aboutSection = document.getElementById('about');
      const servicesSection = document.getElementById('services');
      const careerSection = document.getElementById('career');
      const contactSection = document.getElementById('contact');

      if (
        window.scrollY >= aboutSection.offsetTop &&
        window.scrollY < servicesSection.offsetTop
      ) {
        setActiveLink('О нас');
      } else if (
        window.scrollY >= servicesSection.offsetTop &&
        window.scrollY < careerSection.offsetTop
      ) {
        setActiveLink('Услуги');
      } else if (
        window.scrollY >= careerSection.offsetTop &&
        window.scrollY < contactSection.offsetTop
      ) {
        setActiveLink('Карьера');
      } else if (window.scrollY >= contactSection.offsetTop) {
        setActiveLink('Связаться с нами');
      } else {
        setActiveLink('Главная');
      }
    });
  
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.addEventListener('scroll', handleScroll);
    }
  
    return () => {
      if (aboutSection) {
        aboutSection.removeEventListener('scroll', handleScroll);
      }
    };
  
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [data.length]);

  // Send Data Backend
const handleSubmitBackend = (e) => {
  e.preventDefault();
  const data = { name, phoneNumber, text };

  setIsPanding(true);
}
  return (
    <div>
      <div className='none_container'>
        <section id='home' className="section-1">
          <header>
            <img className='logo' src={Logo} alt="Logo" />
            <nav>
              <a href="#home" className={activeLink === 'Главная' ? 'active' : ''} onClick={() => handleNavClick('Главная')}>Главная</a>
              <a href="#about" className={activeLink === 'О нас' ? 'active' : ''} onClick={() => handleNavClick('О нас')}>О нас</a>
              <a href="#services" className={activeLink === 'Услуги' ? 'active' : ''} onClick={() => handleNavClick('Услуги')}>Услуги</a>
              <a href="#career" className={activeLink === 'Карьера' ? 'active' : ''} onClick={() => handleNavClick('Карьера')}>Карьера</a>
              <a href="#contact" className={activeLink === 'Связаться с нами' ? 'active' : ''} onClick={() => handleNavClick('Связаться с нами')}>Связаться с нами</a>
            </nav>
          </header>

          <div style={{top: '150px', position: 'relative', marginLeft: '150px'}} className="container">
            <div className="d-flex mobile_flex_none">
              <div style={{marginTop: '150px'}}>
                <Reveal>
                  <h1 className='title mobile_title'>КОМФОРТНАЯ И БЕЗОПАСНАЯ <br /> ПОЕЗДКА ВМЕСТЕ C <span style={{color: '#F2EB56'}}>EASYGO</span> </h1>
                  <p className='subtitle'>Теперь вам не нужно беспокоиться о безопасности и <br /> тратить время на поиски транспорта на дальние <br /> расстояния.</p>
                </Reveal>

                <Reveal>
                  <div style={{marginTop: '70px'}} className='mobile_store'>
                    <a href='#'>
                      <img className='store_active' style={{marginRight: '30px'}} src={AppStore} alt="AppStore" />
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=uz.go.easy'>
                      <img className='store_active' style={{marginRight: '20px'}} src={GooglePlay} alt="GooglePlay" />
                    </a>
                    <a href='#'>
                      <img className='store_active' style={{marginRight: '30px'}} src={AppGallery} alt="AppGallery" />
                    </a>
                  </div>
                </Reveal>
              </div>

              <div className='iphone-container'>
                <img src={iPhone} alt="iPhone" />
              </div>

              <Reveal>
                <div style={{marginTop: '70px'}} className='d-flex flex-column none_desktop create_mobile'>
                  <a href='#'>
                    <img className='store_active' style={{marginRight: '30px'}} src={AppStore} alt="AppStore" />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=uz.go.easy'>
                    <img className='store_active' style={{marginRight: '20px'}} src={GooglePlay} alt="GooglePlay" />
                  </a>
                  <a href='#'>
                    <img className='store_active' style={{marginRight: '30px'}} src={AppGallery} alt="AppGallery" />
                  </a>
                </div>
              </Reveal>
            </div>
          </div>
        </section>

        <section id='about'>
          <div className='container'>
            <div className='d-flex'>
              <Reveal>
                <h3 style={{color: 'black', marginBottom: '-20px', marginTop: '100px'}} className='title'>О нас</h3>
                <p style={{color: 'black', width: '533px'}} className='subtitle'>Наша задача обеспечить удобный и бюджетный поиск транспорта в два клика. Мы имеем надежное приложение для комфортного пользования пассажиров и водителей</p>
              </Reveal>
            </div>

            <div>
              <Reveal>
                <Splide options={{ perPage: 3, focus  : 0, omitEnd: true, display: 'flex' }}>
                  {data.map((item, index) => (
                    <SplideSlide key={index}>
                      <div className='about-slides mt-4 mb-4'>
                        <img src={item.image} alt={item.text} />
                        {item.text}
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
              </Reveal>
            </div>
          </div>
        </section>

        <section id='services'>
          <div className="container">
            <div className="d-flex">
              <Reveal>
                <img className='services_like' src={Like} alt="Like" />
              </Reveal>

              <div>
                <div style={{marginTop: '120px', marginLeft: '80px'}}>
                  <Reveal>
                    <h2 className='title' style={{color: 'black'}}>Мы делаем все</h2> 
                    <p className='subtitle' style={{color: 'black', marginTop: '-0px'}}>для лучшего качества сервиса и просим вас нам помочь. Оставляйте честные отзывы и оценки о водителе, о ваших попутчиках и в целом о поездке.</p>
                  </Reveal>

                  <Reveal>
                    <div style={{marginTop: '70px'}}>
                      <a href='#' target='_blank'>
                        <img className='store_active' style={{marginRight: '10px'}} src={AppStore} alt="AppStore" />
                      </a>
                      <a href='https://play.google.com/store/apps/details?id=uz.go.easy'>
                        <img className='store_active' style={{marginRight: '20px'}} src={GooglePlay} alt="GooglePlay" />
                      </a>
                      <a href='#'>
                        <img className='store_active' style={{marginRight: '10px'}} src={AppGallery} alt="AppGallery" />
                      </a>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='career'>
          <div className="container" style={{marginTop: '160px'}}>
            <Reveal>
              <h2 className='title text-center' style={{color: 'black'}}>Cтаньте частью команды <br /> и уже сегодня наслаждайтесь поездкой</h2>
            </Reveal>

            <Reveal>
              <div className="d-flex justify-content-between align-items-center">
                <div className='career-slides'>
                  <Reveal>
                    <h3 style={{width: '241px'}} className='career-title'>стабильный доход</h3>
                    <p style={{width: '241px'}} className='career-subtitle'>Вы можете не только разделить ваши расходы на поездку с попутчиками, но и получить дополнительные бонусы</p>
                  </Reveal>
                </div>

                <div className='career-slides'>
                  <Reveal>
                    <h3 style={{width: '241px'}} className='career-title'>Весело</h3>
                    <p style={{width: '241px'}} className='career-subtitle'>Вы не успеете заскучать в поездке. Ведь с вами рядом всегда много интересных людей.</p>
                  </Reveal>
                </div>

                <div className='career-slides'>
                  <Reveal>
                    <h3 style={{width: '241px'}} className='career-title'>Проверенные люди</h3>
                    <p style={{width: '241px'}} className='career-subtitle'>Опытные водители гарантируют безупречный сервис, лучшие условия и лучшие цены.</p>
                  </Reveal>
                </div>
              </div>
            </Reveal>
          </div>
        </section>

        <section id='contact'>
          {formSubmitted ? (
            <center>
              <div style={{marginTop: '160px', paddingBottom: '20px', paddingTop: '20px', width: '700px'}}>
                <center>
                  <div className="card2 p-3 bg-white rounded-10 fly-sm" style={{width: '520px', padding: '20px 30px', marginTop: '20px', marginBottom: '50px', borderRadius: '20px', boxShadow: '0px 7px 15px 0px rgba(0, 0, 0, 0.10)'}}>
                    <Reveal>
                      <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                        <h3 style={{color: 'black', fontSize: '30px', fontFamily: 'Heebo'}}>Отправлено</h3>
                      </div>
                    </Reveal>

                    <div className={`verification-icon ${isVerified ? 'verified' : ''}`} onClick={handleVerification}>
                      <img style={{width: '150px', height: '150px'}} src={Verified} alt="Verified" />
                    </div>

                    <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                      <p style={{color: 'black', fontSize: '15px', fontFamily: 'Heebo'}}>Ваше мнения и предложения очень важны для нас. <br /> Оставьте свои комментарии и мы обязательно учтем все.</p>
                    </div>
                      
                    <div>
                      <input type="button" className="input_contact-btn" value='Ок ' onClick={handleOkButtonClick} />
                    </div>
                  </div>
                </center>
              </div>
            </center>
          ) : (
            <center>
              {isCenterVisible && (
                <div style={{marginTop: '160px', paddingBottom: '20px', paddingTop: '20px', width: '700px'}}>
                  <center>
                    <div className="card2 p-3 bg-white rounded-10 fly-sm" style={{width: '520px', padding: '20px 30px', marginTop: '20px', marginBottom: '50px', borderRadius: '20px', boxShadow: '0px 7px 15px 0px rgba(0, 0, 0, 0.10)'}}>
                      <Reveal>
                        <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                          <h3 style={{color: 'black', fontSize: '30px', fontFamily: 'Heebo'}}>Обратная связь</h3>
                        </div>
                      </Reveal>

                      <form onSubmit={handleSubmitBackend} className='mt-4 mb-4'>
                        <Reveal>
                          <input
                            type="text"
                            className={`input_contact ${errorFields.name ? 'error' : ''}`}
                            placeholder="Имя"
                            name="name"
                            required
                            value={name}
                            onChange={(e) => {
                              handleInputChange(e);
                              setName(e.target.value);
                            }}
                          />
                        </Reveal>
                        <Reveal>
                          <input
                            type="tel"
                            pattern="[0-9]*"
                            className={`input_contact ${errorFields.phone ? 'error' : ''}`}
                            placeholder="Номер телефона"
                            name="phone"
                            required
                            value={formData.phone}
                            onChange={handleInputChange}
                          />
                        </Reveal>
                        <Reveal>
                          <input
                            type="text"
                            className={`input_contact ${errorFields.message ? 'error' : ''}`}
                            placeholder="Текст сообщения"
                            name="message"
                            required
                            value={text}
                            onChange={(e) => {
                              handleInputChange(e);
                              setText(e.target.value);
                            }}
                          />
                          <input
                            type="hidden"
                            name="message"
                            required
                            value={formData.type}
                            onChange={(e) => {
                              handleInputChange(e);
                              setText(e.target.value);
                            }}
                          />
                        </Reveal>
                      </form>
                        
                      <div>
                        { !isPanding && <input type="button" className="input_contact-btn" value='Отправить' onClick={handleSubmit} />}
                        { isPanding && <input type="button" className="input_contact-btn" value='Отправка...' onClick={handleSubmit} />}
                      </div>
                    </div>
                  </center>
                </div>
              )}
            </center>
          )}
        </section>

        <footer className='footer'>
          <Reveal>
            <a href="tel:+998974179000">
              <img style={{width: '90px', height: '90px'}} src={Call} alt="Call" />
            </a>
          </Reveal>

          <Reveal>
            <h2 className='title text-center' style={{color: 'black', fontSize: '27px', marginTop: '80px', marginLeft: '100px'}}>Свяжитесь с нами, и мы готовы ответить <br /> на любой интересующий Вас вопрос!</h2>
          </Reveal>

          <Reveal>
            <a href="tel:+998974179000" className='text-center footer-call'>+998 97 417 90 00</a>
          </Reveal>

          <Reveal>
            <a href="#" className='d-flex align-items-center justify-content-between' style={{width: '210px', marginTop: '40px'}}>
              <a href='https://t.me/goeasyuz'><img src={Telegram} alt="Telegram" /></a>
              <a href='https://instagram.com/easygo.uz?igshid=MzRlODBiNWFlZA=='><img src={Instagram} alt="Instagram" /></a>
              <img src={Facebook} alt="Facebook" />
            </a>
          </Reveal>
        </footer>
      </div>

      <div
        className="create_carousel"
        style={{
          width: "100%",
          height: 5050,
          position: "relative",
          background: "white",
          overflow: "hidden",
        }}
      >
        <img
          style={{
            width: "20.31px",
            height: "129.47px",
            left: "-244px",
            top: "3589.12px",
            position: "absolute"
          }}
          src="https://via.placeholder.com/20x129"
        />
        <img
          style={{
            width: "20.31px",
            height: "129.47px",
            left: "-223.69px",
            top: "3589.12px",
            position: "absolute"
          }}
          src="https://via.placeholder.com/20x129"
        />
        <img
          style={{
            width: "20.31px",
            height: "129.47px",
            left: "-203.09px",
            top: "3589.12px",
            position: "absolute",
            opacity: "0.20"
          }}
          src="https://via.placeholder.com/20x129"
        />
        <div
          style={{
            width: 1052,
            height: 1214,
            top: "-546px",
            position: "absolute",
            background: "linear-gradient(142deg, rgb(11, 225, 245) 43%, rgb(114, 39, 238) 100%)",
          }}
        />
        <img width={152} height={44} style={{position: "absolute", left: 30, top: 20}} src={Logo} alt="Logo" />
        <div
          style={{ width: 26, height: 17, left: '82%', top: 38, position: "absolute" }}
        >
          <div
            style={{
              width: 26,
              height: 3,
              left: 0,
              top: 0,
              position: "absolute",
              background: "white",
              borderRadius: 20
            }}
          />
          <div
            style={{
              width: 26,
              height: 3,
              left: 0,
              top: 7,
              position: "absolute",
              background: "white",
              borderRadius: 20
            }}
          />
          <div
            style={{
              width: 26,
              height: 3,
              left: 0,
              top: 14,
              position: "absolute",
              background: "white",
              borderRadius: 20
            }}
          />
        </div>
        <div
          style={{
            width: 272,
            left: '17%',
            top: 185,
            position: "absolute",
            textAlign: "center",
            color: "white",
            fontSize: 14,
            fontFamily: "Heebo",
            fontWeight: 400,
            wordWrap: "break-word"
          }}
        >
          <br />
          Теперь вам не нужно беспокоиться о безопасности и тратить время на поиски
          транспорта на дальние <br />
          расстояния.
        </div>
        <div
          style={{
            width: 290,
            height: 112,
            left: '15%',
            top: 115,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 20,
              fontFamily: "Comfortaa",
              fontWeight: 700,
              wordWrap: "break-word"
            }}
          >
            КОМФОРТНАЯ И БЕЗОПАСНАЯ ПОЕЗДКА ВМЕСТЕ C{" "}
          </span>
          <span
            style={{
              color: "#F2EB56",
              fontSize: 20,
              fontFamily: "Comfortaa",
              fontWeight: 700,
              wordWrap: "break-word"
            }}
          >
            EASYGO{" "}
          </span>
        </div>
        <div
          style={{
            width: '100%',
            height: 471,
            top: 326,
            position: "absolute"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "13.21px",
              position: "absolute",
              background:
                "linear-gradient(313deg, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0) 100%)"
            }}
          />
          <img
            style={{ width: '100%', height: 471, top: 0, position: "relative" }}
            src={iPhone}
          />
        </div>

        <div style={{
              width: '100%',
              height: 180,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              top: 800
            }}>
            <img src={AppStoreSvg} alt="AppStoreSvg" />
            <a href='https://play.google.com/store/apps/details?id=uz.go.easy'>
              <img src={GooglePlaySvg} alt="GooglePlaySvg" />
            </a>
            <img src={AppGallerySvg} alt="AppGallerySvg" />
        </div>

        <div
          style={{
            left: '40%',
            top: 1046,
            position: "absolute",
            color: "black",
            fontSize: 24,
            fontFamily: "Comfortaa",
            fontWeight: 400,
            wordWrap: "break-word"
          }}
        >
          О нас
        </div>
        <div style={{
              width: 280,
              left: '15%',
              top: 1110,
              position: "absolute",
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 400,
              wordWrap: "break-word",
              textAlign: "center"
            }}>
          Наша задача обеспечить удобный и бюджетный поиск транспорта в два клика. Мы имеем надежное приложение для комфортного пользования пассажиров и водителей
        </div>
        <div
          style={{
            width: 283,
            height: 451,
            left: 40,
            top: 1034,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              lineHeight: 30,
              wordWrap: "break-word"
            }}
          >
            <br />
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              lineHeight: 20,
              wordWrap: "break-word",
              display: 'none'
            }}
          >
            Наша задача обеспечить удобный и бюджетный поиск транспорта в два клика.
            Мы имеем надежное приложение для комфортного пользования пассажиров и
            водителей
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              lineHeight: 30,
              wordWrap: "break-word"
            }}
          >
            <br />
            <br />
            <br />
          </span>
        </div>
        <div
          style={{
            width: '85%',
            height: 151,
            left: 32,
            top: 1240,
            position: "absolute",
            background: "white",
            boxShadow: "0px 7px 15px rgba(0, 0, 0, 0.10)"
          }}
        />
        <div
          style={{
            width: '85%',
            height: 216,
            left: 32,
            top: 1425,
            position: "absolute",
            background: "white",
            boxShadow: "0px 7px 15px rgba(0, 0, 0, 0.10)"
          }}
        />
        <div
          style={{
            width: '85%',
            height: 216,
            left: 32,
            top: 1675,
            position: "absolute",
            background: "white",
            boxShadow: "0px 7px 15px rgba(0, 0, 0, 0.10)"
          }}
        />
        <div
          style={{
            width: '85%',
            height: 139,
            left: 32,
            top: 1924,
            position: "absolute",
            background: "white",
            boxShadow: "0px 7px 15px rgba(0, 0, 0, 0.10)"
          }}
        />
        <div
          style={{
            width: '85%',
            height: 155,
            left: 32,
            top: 2096,
            position: "absolute",
            background: "white",
            boxShadow: "0px 7px 15px rgba(0, 0, 0, 0.10)"
          }}
        />
        <img
          style={{ width: 62, height: 62, left: 62, top: 1269, position: "absolute" }}
          src={Darts}
        />
        <div
          style={{
            width: 200,
            // height: 286,
            left: 146,
            top: 1228,
            position: "absolute"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            Поиск автомобиля
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            {" "}
            <br />
            на дальние расстояния (другие города, области);
            <br />
          </span>
        </div>
        <div
          style={{
            width: 8,
            height: 153,
            left: 32,
            top: 1238,
            position: "absolute",
            background: "#F2EB56"
          }}
        />
        <div
          style={{
            width: 8,
            height: 218,
            left: 32,
            top: 1423,
            position: "absolute",
            background: "#F2EB56"
          }}
        />
        <div
          style={{
            width: 8,
            height: 218,
            left: 32,
            top: 1673,
            position: "absolute",
            background: "#F2EB56"
          }}
        />
        <div
          style={{
            width: 8,
            height: 140,
            left: 32,
            top: 1923,
            position: "absolute",
            background: "#F2EB56"
          }}
        />
        <div
          style={{
            width: 8,
            height: 156,
            left: 32,
            top: 2095,
            position: "absolute",
            background: "#F2EB56"
          }}
        />
        <img
          style={{ width: 49, height: 49, left: 68, top: 1456, position: "absolute" }}
          src={Lock}
        />
        <div
          style={{
            width: 200,
            // height: 286,
            left: 146,
            top: 1434,
            position: "absolute"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            Безопасность пользования <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 24,
              wordWrap: "break-word"
            }}
          >
            — мы стараемся{" "}
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            отслеживать все отзывы, оценки и профили всех ваших будущих попутчиков и,
            конечно же, водителя
          </span>
        </div>
        <img
          style={{ width: 53, height: 53, left: 64, top: 1704, position: "absolute" }}
          src={Money}
        />
        <div
          style={{
            width: 160,
            // height: 286,
            left: 146,
            top: 1680,
            position: "absolute"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            Поездка по низкой цене
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            {" "}
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            — мы ищем для вас{" "}
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            подходящих попутчиков, соотвественно цена поездки становится ниже;
            <br />
          </span>
        </div>
        <img
          style={{ width: 43, height: 44, left: 74, top: 1954, position: "absolute" }}
          src={Touch}
        />
        <div
          style={{
            width: 154,
            // height: 284,
            left: 146,
            top: 1934,
            position: "absolute"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            Комфортное пользование
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            {" "}
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            — заказ такси в пару{" "}
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            кликов
            <br />
          </span>
        </div>
        <img
          style={{ width: 59, height: 59, left: 63, top: 2123, position: "absolute" }}
          src={Star}
        />
        <div
          style={{
            width: 163,
            // height: 286,
            left: 146,
            top: 2115,
            position: "absolute"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            Удобный сервис
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            {" "}
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            — вы всегда можете{" "}
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            обговорить с водителем цену, которая вам подходит
          </span>
        </div>
        <div
          style={{
            width: '100%',
            height: 442,
            // left: "-440px",
            top: 2410,
            position: "absolute",
            background: "#EFF4FF"
          }}
        />
        <img
          style={{
            width: 260,
            height: 260,
            left: '20%',
            top: 2341,
            position: "absolute"
          }}
          src={Like}
        />
        <div
          style={{
            width: 272,
            // height: 326,
            left: '15%',
            top: 2638,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 22,
              fontFamily: "Comfortaa",
              fontWeight: 700,
              // lineHeight: 20,
              wordWrap: "break-word"
            }}
          >
            Мы делаем все
          </span>
          <span
            style={{
              color: "black",
              fontSize: 20,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 20,
              wordWrap: "break-word"
            }}
          >
            {" "}
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 20,
              wordWrap: "break-word"
            }}
          >
            для лучшего качества сервиса и просим вас нам помочь. Оставляйте честные
            отзывы и оценки о водителе, о ваших попутчиках и в целом о поездке.
          </span>
        </div>
        <div
          style={{
            width: 280,
            height: "28.72px",
            left: 60,
            top: 2773,
            position: "absolute"
          }}
        >
          <img style={{
            width: "83.39px",
            height: "25.40px",
            left: 0,
            top: "1.66px",
            position: "absolute"
          }} src={AppStoreSvg} alt="AppStoreSvg" />
          <a href='https://play.google.com/store/apps/details?id=uz.go.easy'>
            <img style={{
              width: "96.09px",
              height: "28.72px",
              left: "88.92px",
              top: 0,
              position: "absolute"
            }} src={GooglePlaySvg} alt="GooglePlaySvg" />
          </a>
          <img style={{
              width: "94.99px",
              height: "28.72px",
              left: "185.01px",
              top: 0,
              position: "absolute"
            }} src={AppGallerySvg} alt="AppGallerySvg" />
        </div>
        <div
          style={{
            width: 305,
            left: '13%',
            top: 2911,
            position: "absolute",
            textAlign: "center",
            color: "black",
            fontSize: 22,
            fontFamily: "Comfortaa",
            fontWeight: 400,
            // lineHeight: 30,
            wordWrap: "break-word"
          }}
        >
          Cтаньте частью команды и уже сегодня наслаждайтесь поездкой
        </div>
        <div
          style={{
            width: 282,
            height: 282,
            left: '15%',
            top: 3057,
            position: "absolute",
            background: "#2A60DC",
            borderRadius: 9999
          }}
        />
        <div
          style={{
            width: 282,
            height: 282,
            left: '15%',
            top: 3364,
            position: "absolute",
            background: "#2A60DC",
            borderRadius: 9999
          }}
        />
        <div
          style={{
            width: 282,
            height: 282,
            left: '15%',
            top: 3671,
            position: "absolute",
            background: "#2A60DC",
            borderRadius: 9999
          }}
        />
        <div
          style={{
            width: 190,
            // height: 286,
            left: 104,
            top: 3109,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            <br />
          </span>
          <span
            style={{
              color: "#FFF759",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            стабильный доход
            <br />
          </span>
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            Вы можете не только разделить ваши расходы на поездку с попутчиками, но и
            получить дополнительные бонусы.
          </span>
        </div>
        <div
          style={{
            width: 194,
            // height: 286,
            left: 105,
            top: 3423,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            <br />
          </span>
          <span
            style={{
              color: "#FFF86C",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            Весело
            <br />
          </span>
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            Вы не успеете заскучать в поездке. Ведь с вами рядом всегда много
            интересных людей.
          </span>
        </div>
        <div
          style={{
            width: 220,
            // height: 286,
            left: 90,
            top: 3734,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            <br />
          </span>
          <span
            style={{
              color: "#FFF86C",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 700,
              textTransform: "uppercase",
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            Проверенные люди
            <br />
          </span>
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 22,
              wordWrap: "break-word"
            }}
          >
            Опытные водители гарантируют безупречный сервис, лучшие условия и лучшие
            цены.
            <br />
          </span>
        </div>
        <div
          style={{
            left: '27%',
            top: 4053,
            position: "absolute",
            textAlign: "center",
            color: "black",
            fontSize: 22,
            fontFamily: "Comfortaa",
            fontWeight: 400,
            // lineHeight: 60,
            wordWrap: "break-word"
          }}
        >
          Обратная связь
          <br />
        </div>
        <div
          style={{
            width: 283,
            height: 134,
            left: '16%',
            top: 4076,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              textTransform: "uppercase",
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 14,
              fontFamily: "Heebo",
              fontWeight: 300,
              // lineHeight: 18,
              wordWrap: "break-word"
            }}
          >
            Ваше мнения и предложения очень важны для нас. Оставьте свои комментарии и
            мы обязательно учтем все.
            <br />
          </span>
        </div>
        {formSubmitted ? (
            <center className='mb-5'>
              <div style={{marginTop: '160px', paddingBottom: '20px', left: "-134px", top: 4020, position: "absolute", width: '700px'}}>
                <center>
                  <div className="card2 p-3 bg-white rounded-10 fly-sm" style={{width: '520px', padding: '20px 30px', marginTop: '20px', marginBottom: '50px', borderRadius: '20px'}}>
                    <Reveal>
                      <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                        <h3 style={{color: 'black', fontSize: '30px', fontFamily: 'Heebo'}}>Отправлено</h3>
                      </div>
                    </Reveal>

                    <div className={`verification-icon ${isVerified ? 'verified' : ''}`} onClick={handleVerification}>
                      <img style={{width: '150px', height: '150px'}} src={Verified} alt="Verified" />
                    </div>

                    <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                      <p style={{color: 'black', fontSize: '15px', fontFamily: 'Heebo', width: '300px'}}>Ваше мнения и предложения очень важны для нас. <br /> Оставьте свои комментарии и мы обязательно учтем все.</p>
                    </div>
                      
                    <div>
                      <input type="button" className="input_contact-btn me-3" value='Ок' style={{marginBottom: '50px'}} onClick={handleOkButtonClick} />
                    </div>
                  </div>
                </center>
              </div>
            </center>
          ) : (
            <center>
              <div
                style={{
                  width: 320,
                  height: 1431,
                  left: "-642px",
                  top: 4020,
                  position: "absolute",
                  background: "#FAFBFD"
                }}
              />
              <div
                style={{
                  width: 283,
                  height: 53,
                  left: 60,
                  top: 4230,
                  position: "absolute",
                  background: "white",
                  borderRadius: 10,
                  border: "0.50px #B1CAFF solid"
                }}
              />
              <div
                style={{
                  width: 283,
                  height: 53,
                  left: 60,
                  top: 4377,
                  position: "absolute",
                  background: "white",
                  borderRadius: 10,
                  border: "0.50px #B1CAFF solid"
                }}
              />
              <div
                style={{
                  width: 283,
                  height: 53,
                  left: '15%',
                  top: 4466,
                  position: "absolute",
                  background: "#4F43ED",
                  borderRadius: 10,
                  border: "0.50px #4F43ED solid"
                }}
              />
              <div
                style={{
                  width: 283,
                  height: 55,
                  left: 40+20,
                  top: 4302,
                  position: "absolute",
                  background: "white",
                  borderRadius: 10,
                  border: "0.50px #B1CAFF solid"
                }}
              />
              {isCenterVisible && (
                <form onSubmit={handleSubmitBackend} action="">
                  <input
                    style={{
                      left: '16%',
                      top: 4240,
                      position: "absolute",
                      opacity: "0.50",
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Heebo",
                      fontWeight: 300,
                      wordWrap: "break-word"
                    }}
                    type="text"
                    className={`${errorFields.name ? 'error' : ''}`}
                    placeholder="Имя"
                    name="name"
                    required
                    value={name}
                    onChange={(e) => {
                      handleInputChange(e);
                      setName(e.target.value);
                    }}
                  />
                  <input
                    style={{
                      left: '16%',
                      top: 4315,
                      position: "absolute",
                      opacity: "0.50",
                      // textAlign: "center",
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Heebo",
                      fontWeight: 300,
                      // lineHeight: 20,
                      wordWrap: "break-word"
                    }}
                    type="tel"
                    className={`${errorFields.phone ? 'error' : ''}`}
                    placeholder="Номер телефона"
                    name="phone"
                    required
                    value={phoneNumber}
                    onChange={(e) => {
                      handleInputChange(e);
                      setPhoneNumber(e.target.value);
                    }}
                  />
                  <input
                    style={{
                      left: '16%',
                      top: 4390,
                      position: "absolute",
                      opacity: "0.50",
                      // textAlign: "center",
                      color: "black",
                      fontSize: 16,
                      fontFamily: "Heebo",
                      fontWeight: 300,
                      // lineHeight: 20,
                      wordWrap: "break-word"
                    }}
                    type="text"
                    className={`${errorFields.message ? 'error' : ''}`}
                    placeholder="Текст сообщения"
                    name="message"
                    required
                    value={text}
                    onChange={(e) => {
                      handleInputChange(e);
                      setText(e.target.value);
                    }}
                  />
                  { !isPanding && <button
                    style={{
                      left: 135,
                      top: 4483,
                      position: "absolute",
                      textAlign: "center",
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Heebo",
                      fontWeight: 700,
                      // lineHeight: 20,
                      backgroundColor: "transparent",
                      border: 'none',
                      wordWrap: "break-word"
                    }}
                    onClick={handleSubmit}
                  >
                    Отправить
                  </button>}
                  { isPanding && <button
                    style={{
                      left: 135,
                      top: 4483,
                      position: "absolute",
                      textAlign: "center",
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Heebo",
                      fontWeight: 700,
                      // lineHeight: 20,
                      backgroundColor: "transparent",
                      border: 'none',
                      wordWrap: "break-word"
                    }}
                    onClick={handleSubmit}
                  >
                    Отправка...
                  </button>}
                </form>
              )}
            </center>
          )}
        <div
          style={{
            left: "-290px",
            top: "4570.02px",
            position: "absolute",
            backgroundImage: "url(" + FooterBg + ")",
            backgroundSize: "cover",
            width: "175%",
            height: "500px",
            paddingLeft: '88%'
          }}
        >
          <div
            style={{
              width: 275,
              textAlign: "center",
              color: "black",
              fontSize: 20,
              fontFamily: "Comfortaa",
              fontWeight: 300,
              // lineHeight: 30,
              top: 120,
              wordWrap: "break-word",
              position: "absolute"
            }}
          >
            Свяжитесь с нами, и мы готовы ответить
            <br /> на любой интересующий Вас вопрос!
          </div>
          <div
            style={{
              color: "black",
              fontSize: 36,
              fontFamily: "Dangrek",
              fontWeight: 400,
              wordWrap: "break-word",
              position: "absolute",
              top: 300
            }}
          >
            +998 97 417 90 00
          </div>
          <center style={{ width: 100 }}>
            <img
              style={{ width: 49, height: 49, marginLeft: 50, position: "absolute", top: 40 }}
              src={Call}
            />
          </center>
          <div style={{ width: 146, height: "39.17px", position: "relative", top: 380, left: 60 }}>
            <a href="https://t.me/goeasyuz" target="_blank">
              <img
                style={{
                  width: "39.17px",
                  height: "39.17px",
                  left: "54.13px",
                  top: 0,
                  position: "absolute"
                }}
                src={Telegram}
              />
            </a>
            <a href="https://instagram.com/easygo.uz?igshid=MzRlODBiNWFlZA==" target="_blank">
              <img
                style={{
                  width: "39.17px",
                  height: "39.17px",
                  left: 0,
                  top: 0,
                  position: "absolute"
                }}
                src={Instagram}
              />
            </a>
            <img
              style={{
                width: "39.17px",
                height: "39.17px",
                left: "106.83px",
                top: 0,
                position: "absolute"
              }}
              src={Facebook}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
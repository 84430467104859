import React from 'react'
import { useNavigate } from 'react-router-dom'

function TermsOfUse() {
  const navigate = useNavigate()

    return (
        <div style={{ padding: '10px' }}>
            <div className='d-flex mb-5' style={{marginLeft: '70px'}}>
                <p onClick={() => navigate(-1)} className='headerText'>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</p>
            </div>

            <p className='mobile_text p-1' style={{marginTop: '-60px', textAlign: 'center', color: 'black', opacity: '1'}}>
                об использовании мобильного приложения «EasyGo»
            </p>

            <p className='mobile_text p-1' style={{textAlign: 'center', color: 'black', opacity: '1', fontSize: '15px'}}>
                <i>
                    Убедительно просим перед началом использования программного обеспечения внимательно ознакомиться с текстом настоящего соглашения (публичная оферта), и, если вы не согласны с каким-либо пунктом, Вам предлагается отказаться от дальнейшего использования программного обеспечения.
                </i>
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                Общество с ограниченной ответственностью «DOCLINE» (далее – Компания), в лице директора Саматов А.А., действующего на основании Устава, предлагает любому дееспособному физическому лицу, отвечающему определенным требованиям, и намеревающемуся использовать Мобильное приложение «EasyGo» (далее – Пользователь), заключить настоящее соглашение об использовании Мобильного приложения «EasyGo» (далее, «Соглашение») посредством совершения Пользователем акцепта настоящей публичной оферты. <br />
                Соглашение об использовании Мобильного приложения «EasyGo» является заключенным между Компанией и Пользователем с момента установки Пользователем Мобильного приложения «EasyGo» на свое Устройство и осуществления Пользователем Регистрации в Мобильном приложении «EasyGo» в порядке, определенном настоящим Соглашением. <br />
                Любое использование Пользователем Мобильного приложения «EasyGo» означает полное и безоговорочное принятие им условий настоящего Соглашения. <br />
                Если Пользователь не принимает условия Соглашения в полном объеме, Пользователь не имеете права использовать Мобильное приложение «EasyGo» в каких-либо целях. <br />
                Соглашение содержит специальные нормы, которые регулируют отношения между Компанией и Пользователем по использованию Приложения.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                <b>1. Термины, используемые в настоящем Соглашении</b>
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; <b>1.1. Компания</b> - Общество с ограниченной ответственностью «DOCLINE», юридическое лицо, созданное по законодательству Республики Узбекистан, имеющее место нахождения по адресу: Республика Узбекистан, г.Ташкент, Мирабадский район, ул. А.Темура шох, дом 47.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; <b>1.2. Пользователь</b> - физическое лицо, использующее мобильное приложение «EasyGo» и заключившее соглашение с Компанией путем акцепта.            
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; <b>1.3. Мобильное</b> приложение – программное обеспечение для мобильных электронных устройств, актуальная версия которого размещена правообладателем в Интернет сервисе AppStore и Google Play, доступных в публичной информационно-телекоммуникационной сети «Интернет» по сетевым адресам: itunes.apple.com и play.google.com соответственно. Мобильное приложение, в том числе, представляет собой информационную систему, оператором которой является Компания. Исключительное право на Мобильное приложение принадлежит Компании в силу факта создания. Системные требования к установке мобильного приложения: iOS и Android.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; <b>1.4. Авторизация</b> Пользователя – осуществление определенного настоящим Соглашением набора действий в Мобильном приложении в целях идентификации зарегистрированного Пользователя для последующего использования функционала Мобильного приложения.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; <b>1.5. Служба</b> поддержки – служба Компании, осуществляющая в режиме двадцать четыре часа, семь дней в неделю, без выходных и праздничных дней, консультационную поддержку Пользователей, а также помощь Пользователям и их информирование в процессе использования Мобильного приложения. Поддержка и консультирование осуществляются при обращении Пользователя в Службу поддержки по телефону _______________ или по электронной почте ________________, а также в службе поддержки в Мобильном приложении.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; <b>1.6. Контент</b> – все объекты, самостоятельно размещенные Пользователем в Мобильном приложении, включая графические, текстовые, фото – объекты (произведения), являющиеся объектами интеллектуальных прав или не являющиеся таковыми, права (в том числе исключительное право на объекты интеллектуальных прав) на которые принадлежат либо Пользователю, либо иным правообладателям.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; <b>1.7. Устройство</b> – мобильное электронное устройство Пользователя (смартфон, мобильный телефон), подключенное к публичной информационно-телекоммуникационной сети Интернет, работающее на базе операционной системы iOS или Android, на котором установлено Мобильное приложение.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                <b>2. Предмет Соглашения</b>
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 2.1. Компания предоставляет Пользователю право на использование Мобильного приложения Компании на условиях Лицензии в пределах и способами, определенными настоящим Соглашением. Срок действия Лицензии – с даты начала использования Пользователем Мобильного приложения до момента удаления учетной записи в мобильном приложении «EasyGo», путем направления запроса на электронную почту __________________.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 2.2. Использование Мобильного приложения допускается на территории всех стран мира.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 2.3. Компания предоставляет Пользователю право использовать Мобильное приложение по его прямому функциональному назначению для получения услуг Компании. 
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 2.4. Мобильное приложение предоставляется Пользователю в целях получения услуг Компании. Иные способы использования Мобильного приложения, кроме прямо предусмотренных в настоящем Соглашении, запрещены.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 2.5. Стороны Компании соглашения и иные значимые документы либо публикуются в Мобильном приложении в форме публичной оферты или иного одностороннего волеизъявления Компании, либо подписываются простой электронной подписью, при этом под подписанными простой электронной подписью Компании Стороны понимают сообщения, генерируемые и отправляемые Пользователю Мобильным приложением push-уведомления либо СМС-сообщений, и тем самым позволяющие идентифицировать Компанию.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 2.6. Компания предоставляет Пользователю Мобильное приложение, а также любую информацию, содержащуюся в Мобильном приложении на условиях «как есть» («as is») без гарантий любого рода. Компания не предоставляет никаких гарантий в отношении безошибочной и бесперебойной работы Мобильного приложения или отдельных его компонентов и/или функций, соответствия Мобильного приложения конкретным целям и ожиданиям Пользователя, а также не предоставляет никаких иных гарантий, прямо не указанных в настоящем Соглашении.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                <b>3. Правила использования Приложения</b>
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.1. Для полноценного использования Приложения Пользователем необходимо авторизоваться в Приложении при помощи сотового номера телефона и пароля, полученного после ввода номера телефона в соответствующее окно в интерфейсе Приложения.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.2. После авторизации на экране авторизации Пользователь вправе использовать Приложение при условии соблюдения настоящего Соглашения.  
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.3. Лицо, авторизовавшееся на экране авторизации, считается надлежащим владельцем Приложения, доступ к использованию и управлению которого были получены в результате такой авторизации. Логин и пароль используются для авторизации в Приложении. Многократный неверный ввод таких данных может вести к временной блокировке Приложения. Если Пользователем не доказано обратное, любые действия, совершенные с использованием его мобильного устройства, считаются совершенными соответствующим Пользователем. В случае несанкционированного доступа к его мобильному устройству Пользователя обязан незамедлительно сообщить об этом Компании.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.4. При невозможности совершения авторизации в связи с неверно введенным сотовым номером телефона и по иным причинам, Пользователь, помимо выполнения условий, предусмотренных в Соглашении для самостоятельного восстановления доступа, вправе обратиться в службу поддержки Компании. Способы восстановления доступа к Приложению, могут быть изменены, отменены или дополнены Компанией в одностороннем порядке.  
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.5. Компания предпринимает коммерчески обоснованные усилия для обеспечения функционирования Приложения в круглосуточном режиме, однако не гарантирует отсутствие перерывов, связанных с техническими неисправностями, проведением профилактических работ. Компания не гарантирует, что Приложение или любые его элементы будут функционировать в любое конкретное время в будущем или что они не прекратят работу.  
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.6. Компания предоставляет Пользователю право использовать Контент исключительно для целей функционирования Приложения необходимым для этого способом. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.7. Пользователь не в праве осуществлять следующие действия: 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.7.1. изменять, воспроизводить, копировать, перерабатывать (включая перевод), распространять (включая продажу, прокат, аренду и пр.), опубликовывать или модифицировать Приложение, полностью и в частях; 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.7.2. декомпилировать, дизассемблировать, дешифровать, а также иным способом пытаться извлечь объектный код Приложения и его компонентов с целью их использования прямо не указанным в текущем Соглашении способом, позволяющим получить несанкционированный доступ к Приложению и заложенным в нем алгоритмам; 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.7.3. выполнять иные действия или использовать Приложение иным способом, прямо не предусмотренным настоящим Соглашением. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.8. Пользователь не вправе загружать или иным образом доводить до всеобщего сведения Контент и прочие результаты интеллектуальной деятельности Компании и иных лиц- правообладателей, при отсутствии явным образом выраженного согласия правообладателя и/или необходимого объема прав на такие действия. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.9. В случае электронной оплаты через банковскую карту Пользователь предоставляет номер банковской карты и сведения о банковской карте направляются через шлюз передачи данных непосредственно в банк, обслуживающий Компанию, исключая получение Компанией вводимых Пользователем данных банковской карты. Компания получает от банка, обслуживающего Компанию лишь сведения о номере банковской карты в виде маски 1111 **** **** 1111. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.9.1. пользователь гарантирует, что предоставленные им при Регистрации в Мобильном приложении данные являются полными, действительными и достоверными, что он является держателем банковской карты, введенной им. Мобильном приложении. Компания оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных, указанных при Регистрации, и запросить в связи с этим подтверждающие документы, непредоставление которых, по усмотрению Компании, может быть приравнено к предоставлению недостоверной информации и повлечь отказ в Регистрации блокировку Учетной записи Пользователя или расторжение настоящего Соглашения.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.9.2. Вводя в Мобильном приложении данные своей банковской карты, Пользователь дает согласие на списание Компанией или по ее поручению привлеченным банком (платежной системой) денежных средств с указанной банковской карты в счет любых платежей и погашения задолженности.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 3.10. Пользователь дает свое согласие Компании в целях исполнения настоящего Соглашения, осуществлять обработку персональных данных Пользователя, передавать их третьим лицам, а равно привлекать третьих лиц к обработке персональных данных Пользователя без выплаты ему вознаграждения, как без использования средств автоматизации, так и с их использованием. В ходе обработки персональных данных могут быть совершены следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение, а также любые иные действия. Лицензиат соглашается, что персональные данные могут обрабатываться в течение срока деятельности Соглашения. Хранение персональных данных осуществляется согласно Закону Республики Узбекистан «О персональных данных».
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                <b>4. ОБЯЗАННОСТИ И ОТВЕТСТВЕННОСТЬ ПОЛЬЗОВАТЕЛЯ</b>
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.1. Пользователь обязуется соблюдать положения настоящего Соглашения и действующее законодательство Республики Узбекистан, а также законные требования Компании.  
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.2. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Соглашению Пользователь несет ответственность в соответствии с настоящим Соглашением действующим и законодательством Республики Узбекистан. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.3. Пользователь несет ответственность за все действия, осуществляемые им в Мобильном приложении. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.4. Пользователь обязуется самостоятельно отслеживать обновления Мобильного приложения и своевременно устанавливать на своем Устройстве новую версию Мобильного приложения.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.5. Пользователь не должен допускать использование любым третьим лицом Логина и Кода подтверждения. Компания не отвечает за возможный ущерб, потерю или порчу данных, которые могут произойти из-за нарушения Пользователем данного положения. Пользователь обязуется принимать надлежащие меры для сохранения в тайне данных, используемых для Авторизации в Мобильном приложении, и незамедлительно сообщать Компании о факте использования таких данных третьими лицами. Пользователь несет все риски неблагоприятных последствий, связанных с отсутствием такого сообщения.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.6. Пользователь обязуется в случае смены номера мобильного телефона, и иных данных, указанных при Регистрации, сообщить об этом Компании. Пользователь несет все риски неблагоприятных последствий, связанных с отсутствием такого сообщения.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.7. Пользователь обязуется не использовать какие-либо технологии и не предпринимать никаких действий, которые могут нанести вред Мобильному приложению, интересам и имуществу Компании. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.8. О наличии ошибок или неполадок, возникших в процессе использования Мобильного приложения Пользователь обязуется сообщать в Службу поддержки Компании.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.9. Пользователь настоящим соглашается с тем, что Мобильное приложение в целях реализации своего полноценного функционала может осуществлять доступ к камерам Устройства с целью получения и использования фотоизображений в рамках услуг, реализуемых в Мобильном приложении, в том числе для создания и сохранения фотоизображений в профиле Пользователя в Мобильном приложении, а также для других целей, необходимых при использовании Мобильного приложения, включая для целей получения услуг через Мобильное приложение.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 4.10. Настоящее Соглашение не дает Пользователю никаких прав на использование объектов интеллектуальной собственности, включая товарные знаки и знаки обслуживания Компании или его партнеров, за исключением прав, прямо предоставляемых настоящим Соглашением.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                5. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ КОМПАНИИ
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 5.1. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Соглашению Компания несет ответственность в соответствии с настоящим Соглашением действующим и законодательством Республики Узбекистан 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 5.2. Компания не несет ответственности за временную неработоспособность платежных систем, обеспечивающую прием и перевод платежей Пользователей, вызванную не зависящими от Компании причинами, а также обстоятельствами непреодолимой силы. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 5.3. Компания не несет ответственность за использование Мобильного приложения с Устройства Пользователя третьими лицами, в связи с чем все действия, совершенные с Устройства Пользователя, считаются действиями Пользователя.  В случае, если какое-либо лицо получает доступ к Мобильному приложению под Учетной записью Пользователя и возможность ее использования от имени Пользователя, Пользователь обязан незамедлительно сообщить об этом Компании в письменном виде (по электронной почте), либо обратившись в Службу поддержки. В противном случае все действия, совершенные от имени Пользователя с использованием Мобильного приложения, будут расцениваться как действия, совершенные непосредственно Пользователем.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 5.4. Действия любых третьих лиц, а также действия платежных систем, операторов связи не являются действиями Компании, и ответственности за них Компания не несет. 
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 5.5. Компания не несет ответственности за нарушение условий настоящего Соглашения, если такое нарушение вызвано действием обстоятельств непреодолимой силы (форсмажор), включая среди прочих: действия и решения органов государственной власти и/или местного самоуправления, пожар, наводнение, землетрясение, другие стихийные действия, отсутствие электроэнергии и/или сбои работы компьютерной сети, забастовки, гражданские волнения, беспорядки, любые иные обстоятельства, которые могут повлиять на выполнение Компанией условий настоящей Соглашения.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 5.6. Компания не несет ответственности за неисполнение либо ненадлежащее исполнение своих обязательств, если это вызвано действиями/бездействием Пользователя, находящимися вне контроля Компании, в том числе в результате совершения ошибочных действий/бездействия Пользователя.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 5.7. Компания не контролирует содержание Контента, размещенного, передаваемого, используемого в Мобильном приложении. Пользователь несет самостоятельную личную ответственность за любой Контент или иную информацию, которую он размещает, передает и/или использует в Мобильном приложении или с его помощью. В случае предъявления Компании претензий о нарушении прав третьих лиц, а также при получении соответствующих запросов от уполномоченных государственных органов о нарушении действующего законодательства в связи с размещением, использованием, передачей Контента и/или информации Пользователем и/или при возникновении соответствующих рисков, Компания имеет право удалить соответствующий Контент и/или информацию.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                6. БЛОКИРОВКА ПОЛЬЗОВАТЕЛЕЙ
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 6.1. Компания вправе блокировать Учетную запись Пользователя (приостановить доступ к Мобильному приложению) при наличии следующих обстоятельств:
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; • в случае нарушения Пользователем условий настоящего Соглашения;
                &nbsp; &nbsp; &nbsp; &nbsp; • при наличии у Пользователя неоплаченной задолженности после получение услуг и опубликования тарифов в приложении;
                &nbsp; &nbsp; &nbsp; &nbsp; • в случае возникновения подозрений недобросовестности Пользователя (в том числе, но не ограничиваясь, при предоставлении Пользователем недостоверных сведений, невозможности списания денежных средств и проч.);
                &nbsp; &nbsp; &nbsp; &nbsp; • в случаях попытки Пользователя ввести Компанию в заблуждение, предоставить недостоверные данные, исказить факты;
                &nbsp; &nbsp; &nbsp; &nbsp; • в случаях отказа выполнения требований Компании, предусмотренных или вытекающих из настоящего Соглашения, в том числе путем бездействия;
                &nbsp; &nbsp; &nbsp; &nbsp; • в случаях, когда Пользователь не соответствует (прекратил соответствовать) требованиям, предъявляемым Компанией к Пользователю;
                &nbsp; &nbsp; &nbsp; &nbsp; • в иных случаях, когда нарушения Пользователем, по мнению Компании, исключают возможность дальнейшего использования Мобильного приложения Пользователем;
                &nbsp; &nbsp; &nbsp; &nbsp; • В случае бездействия Пользователя под Учетной записью в течении 6 месяцев.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 6.2.	Блокировка Учетной записи Пользователя означает ограничение доступного Пользователю функционала Мобильного приложения под его Учетной записью.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 6.3.	Снятие блокировки Учетной записи Пользователя может быть связано с истечением определенного периода времени, выполнением Пользователем своих обязательств, устранения обстоятельств, явившихся основаниями для блокировки, а также иных требований Компании. Блокировка Учетной записи может быть установлена на период принятия решения о целесообразности дальнейшего взаимодействия с Пользователем по Договору.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 6.4.	Пользователь соглашается с тем, что блокирование Учетной записи не является основанием для предъявления Пользователем каких бы то ни было требований, в том числе требований, связанных с невозможностью бронирования ТС.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                7. РАЗРЕШЕНИЕ СПОРОВ И ПОДСУДНОСТЬ
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 7.1. Все споры и разногласия по настоящему Соглашению разрешаются путем переговоров с соблюдением обязательного претензионного порядка.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 7.2. В случае, если Компания и Пользователь не пришли к решению посредством переговоров в течение 15 (пятнадцати) дней, споры и разногласия передаются на разрешение в суд, в порядке, предусмотренном действующим законодательством Республики Узбекистан по месту нахождения Компании.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 7.3. По всем вопросам, связанным с использованием Мобильного приложения, и со всеми претензиями Пользователь может обращаться в Службу поддержки Компании. При обращении с претензией к Компании Пользователь должен предоставить документы, подтверждающие обоснованность претензии, а также указать свои данные, предоставленные им при Регистрации.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                8. ПРОЧИЕ УСЛОВИЯ
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 8.1. В части, не урегулированной настоящим Соглашением, отношения Компании и Пользователя регулируются действующим законодательством Республики Узбекистан вне зависимости от места нахождения Компании, Пользователя или Устройства Пользователя.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; 8.2. Пользователь, не принявший условия настоящего Соглашения, либо принявший их по ошибке, должен сообщить об этом Компании и не вправе пользоваться Мобильным приложением.
            </p>

            <p className='mobile_text p-1' style={{color: 'black', textAlign: 'center', opacity: '1', fontSize: '18px'}}>
                9. РЕКВИЗИТЫ КОМПАНИИ
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; Общество с ограниченной ответственностью «DOCLINE»
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; Адрес: Республика Узбекистан, г.Ташкент, Мирабадский район, ул.Амир Темур шох, 47 дом.
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; ИНН 306 831 006
            </p>
            
            <p className='mobile_text p-1' style={{color: 'black', opacity: '1', fontSize: '18px'}}>
                &nbsp; &nbsp; &nbsp; &nbsp; Директор ООО «DOCLINE»	______________	Саматов А.А.
            </p>
        </div>
    )
}

export default TermsOfUse
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

function Answer() {
  const navigate = useNavigate()

  return (
    <>
        <div style={{ padding: '10px', marginLeft: '-20px' }}>
            <div className='d-flex mb-5'>
                <p onClick={() => navigate(-1)} className='headerText'>Вопросы и ответы</p>
            </div>
        </div>


        <div style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', width: '100%', height: '90vh', background: 'rgb(223 223 223 / 54%)', marginTop: '-50px', padding: '20px' }}>
            <NavLink to={'/imDriver'} style={{ textDecoration: 'none' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Я водитель</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/imPassager'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Я пассажир</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/allAnswer'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Общие вопросы</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>
        </div>  
    </>
  )
}

export default Answer
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

function EditPassager() {
  const navigate = useNavigate()

  return (
    <>
        <div style={{ padding: '10px', marginLeft: '-20px' }}>
            <div className='d-flex mb-5'>
                <button onClick={() => navigate(-1)} style={{ fontFamily: 'Jaldi', background: 'none', border: 'none', fontSize: '50px', marginTop: '-18px' }}>
                    <svg style={{ width: '20px', height: '20px' }} onClick={() => navigate(-1)} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width={30} height={30} viewBox="0 0 256 256" xmlSpace="preserve">
                        <defs></defs>
                        <g style={{ stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1 }} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            <path
                                d="M 65.75 90 c 0.896 0 1.792 -0.342 2.475 -1.025 c 1.367 -1.366 1.367 -3.583 0 -4.949 L 29.2 45 L 68.225 5.975 c 1.367 -1.367 1.367 -3.583 0 -4.95 c -1.367 -1.366 -3.583 -1.366 -4.95 0 l -41.5 41.5 c -1.367 1.366 -1.367 3.583 0 4.949 l 41.5 41.5 C 63.958 89.658 64.854 90 65.75 90 z"
                                style={{ stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1 }}
                                transform=" matrix(1 0 0 1 0 0) "
                                strokeLinecap="round"
                            />
                        </g>
                    </svg>
                </button>
                <p className='headerText'>Редактирование поездки</p>
            </div>
        </div>

        <div style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', width: '100%', height: '100%', background: 'rgb(223 223 223 / 54%)', marginTop: '-50px', padding: '20px' }}>
            <NavLink to={'/driverEditorEditGorod'} style={{ textDecoration: 'none' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Как изменить маршрут или добавить промежуточные города?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditDate'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Как изменить дату и время поездки?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditPrice'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Как изменить стоимость поездки?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditSeats'} style={{ textDecoration: 'none' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Как изменить количество свободных мест в поездке?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditTrips'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Почему я не могу отредактировать поездку?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditCencel'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Как отменить поездку?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditSee'} style={{ textDecoration: 'none' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Где посмотреть на созданную поездку?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditCreate'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Как создать поездку?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>

            <NavLink to={'/driverEditorEditManyTrips'} style={{ textDecoration: 'none', color: '#7A7D7F' }}>
                <div className='d-flex justify-content-between'>
                    <p className='mobile_text'>Сколько поездок можно создать?</p>
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 50L80 50M80 50L60 30M80 50L60 70"
                            stroke="#7A7D7F"
                            strokeWidth={5}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <hr style={{ marginTop: '-8px', color: '#7A7D7F' }} />
            </NavLink>
        </div>  
    </>
  )
}

export default EditPassager
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home'

// Answer
import Answer from './mobile/Вопросы и ответы';

// Driver
import ImDriver from './mobile/Вопросы и ответы/Я водитель';
import EditPassager from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки';
import DriverEditorEditGorod from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Как изменить маршрут или добавить промежуточные города/index';
import DriverEditorEditDate from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Как изменить дату и время поездки/index';
import DriverEditorEditPrice from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Как изменить стоимость поездки/index';
import DriverEditorEditSeats from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Как изменить количество свободных мест в поездке';
import DriverEditorEditTrips from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Почему я не могу отредактировать поездку';
import DriverEditorEditCencel from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Как отменить поездку';
import DriverEditorEditSee from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Где посмотреть на созданную поездку';
import DriverEditorEditCreate from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Как создать поездку';
import DriverEditorEditManyTrips from './mobile/Вопросы и ответы/Я водитель/Редактирование поездки/Сколько поездок можно создать';

import BronePassager from './mobile/Вопросы и ответы/Я водитель/Бронирование поездки';
import DriverBroneType from './mobile/Вопросы и ответы/Я водитель/Бронирование поездки/Какие бывают типы бронирования';
import DriverBroneeditType from './mobile/Вопросы и ответы/Я водитель/Бронирование поездки/Что делать, если я не могу связаться с пассажиром';

import PayPassager from './mobile/Вопросы и ответы/Я водитель/Оплата поездки';
import DriverPayTravel from './mobile/Вопросы и ответы/Я водитель/Оплата поездки/Когда попутчик оплачивает поездку';

// Passenger
import ImPassager from './mobile/Вопросы и ответы/Я пассажир';
import ImPassagerBrone from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки';
import PassagerBroneType from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Какие бывают типы бронирования';
import PassagerBroneAccesDriver from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Как связаться с водителем';
import PassagerBroneAccesComfotr from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Почему у меня нет возможности связаться с водителем по телефону';
import PassagerBroneAccesWhatTime from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Сколько времени рассматривают запрос на бронирование';
import PassagerBroneNotWorking from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Почему мои бронирования отклоняют';
import PassagerBroneNotWorkingSites from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Почему я не могу забронировать место в поездке';
import PassagerAccesBrone from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Как забронировать поездку';
import PassagerAccesBroneCencel from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Что делать если водитель отменил поездку и не приехал';
import PassagerAccesBroneDeystvie from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Водитель подтвердил бронирование';
import PassagerAccesCencelBrone from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Как отменить бронирование';
import PassagerAccesRepaetBrone from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Почему не получается повторно забронироваться в той же поездке';
import PassagerAccesWorkBrone from './mobile/Вопросы и ответы/Я пассажир/Бронирование поездки/Как работает доступ к бронированиям';

import ImPassagerTrips from './mobile/Вопросы и ответы/Я пассажир/Поездки';
import PassagerTripsFind from './mobile/Вопросы и ответы/Я пассажир/Поездки/Как найти поездку';
import PassagerTripsFindCar from './mobile/Вопросы и ответы/Я пассажир/Поездки/Что делать если, водитель изменил маршрут';
import PassagerTripsFindNotification from './mobile/Вопросы и ответы/Я пассажир/Поездки/Как мне получать уведомления о новых поездках';

import ImPassagerPay from './mobile/Вопросы и ответы/Я пассажир/Оплата поездки';
import PassagerWhatIsPay from './mobile/Вопросы и ответы/Я пассажир/Оплата поездки/Оплата поездки Картой';

// All Answers
import AllAnswer from './mobile/Вопросы и ответы/Общие вопросы';
import AllAnswerConnectPhone from './mobile/Вопросы и ответы/Общие вопросы/Как с вами связаться по телефону/indeex';
import AllAnswerService from './mobile/Вопросы и ответы/Общие вопросы/Со скольки лет можно пользоваться сервисом';

// Пожелания по улучшению сервиса
import SendFormMobileApp from './mobile/Пожелания по улучшению сервиса';

// ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
import TermsOfUse from './mobile/Пользовательское соглашение';

// ВОДИТЕЛЬСКОЕ УДОСТОВЕРЕНИЕ
import PassagerSendInBackendStep1 from './mobile/Водительское Удостоверение/step 1';

// Политика конфиденциальности
import PrivacyAgency from './mobile/Политика конфиденциальности';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/answer' element={<Answer />} />

        <Route path='/imDriver' element={<ImDriver />} />
        <Route path='/editPassager' element={<EditPassager />} />
        <Route path='/driverEditorEditGorod' element={<DriverEditorEditGorod />} />
        <Route path='/driverEditorEditDate' element={<DriverEditorEditDate />} />
        <Route path='/driverEditorEditPrice' element={<DriverEditorEditPrice />} />
        <Route path='/driverEditorEditSeats' element={<DriverEditorEditSeats />} />
        <Route path='/driverEditorEditTrips' element={<DriverEditorEditTrips />} />
        <Route path='/driverEditorEditCencel' element={<DriverEditorEditCencel />} />
        <Route path='/driverEditorEditSee' element={<DriverEditorEditSee />} />
        <Route path='/driverEditorEditCreate' element={<DriverEditorEditCreate />} />
        <Route path='/driverEditorEditManyTrips' element={<DriverEditorEditManyTrips />} />

        <Route path='/bronePassager' element={<BronePassager />} />
        <Route path='/driverBroneType' element={<DriverBroneType />} />
        <Route path='/driverBroneeditType' element={<DriverBroneeditType />} />

        <Route path='/payPassager' element={<PayPassager />} />
        <Route path='/driverPayTravel' element={<DriverPayTravel />} />

        <Route path='/imPassager' element={<ImPassager />} />
        <Route path='/imPassagerBrone' element={<ImPassagerBrone />} />
        <Route path='/passagerBroneType' element={<PassagerBroneType />} />
        <Route path='/passagerBroneAccesDriver' element={<PassagerBroneAccesDriver />} />
        <Route path='/passagerBroneAccesComfotr' element={<PassagerBroneAccesComfotr />} />
        <Route path='/passagerBroneAccesWhatTime' element={<PassagerBroneAccesWhatTime />} />
        <Route path='/passagerBroneNotWorking' element={<PassagerBroneNotWorking />} />
        <Route path='/passagerBroneNotWorkingSites' element={<PassagerBroneNotWorkingSites />} />
        <Route path='/passagerAccesBrone' element={<PassagerAccesBrone />} />
        <Route path='/passagerAccesBroneCencel' element={<PassagerAccesBroneCencel />} />
        <Route path='/passagerAccesBroneDeystvie' element={<PassagerAccesBroneDeystvie />} />
        <Route path='/passagerAccesCencelBrone' element={<PassagerAccesCencelBrone />} />
        <Route path='/passagerAccesRepaetBrone' element={<PassagerAccesRepaetBrone />} />
        <Route path='/passagerAccesWorkBrone' element={<PassagerAccesWorkBrone />} />

        <Route path='/imPassagerTrips' element={<ImPassagerTrips />} />
        <Route path='/passagerTripsFind' element={<PassagerTripsFind />} />
        <Route path='/passagerTripsFindCar' element={<PassagerTripsFindCar />} />
        <Route path='/passagerTripsFindNotification' element={<PassagerTripsFindNotification />} />

        <Route path='/imPassagerPay' element={<ImPassagerPay />} />
        <Route path='/passagerWhatIsPay' element={<PassagerWhatIsPay />} />

        <Route path='/allAnswer' element={<AllAnswer />} />
        <Route path='/allAnswerConnectPhone' element={<AllAnswerConnectPhone />} />
        <Route path='/allAnswerService' element={<AllAnswerService />} />


        <Route path='/sendFormMobileApp' element={<SendFormMobileApp />} />


        <Route path='/termsOfUse' element={<TermsOfUse />} />


        <Route path='/passagerSendInBackendStep/:token' element={<PassagerSendInBackendStep1 />} />


        <Route path='/privacyAgency' element={<PrivacyAgency />} />
      </Routes>
    </div>
  )
}

export default App
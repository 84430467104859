import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Front from '../../../layouts/images/front.png';
import Back from '../../../layouts/images/back.svg';
import Front2 from '../../../layouts/images/front.png'
import Back2 from '../../../layouts/images/back.svg'
import Error from '../../../layouts/giphy/giphy.gif'
import Verifed from '../../../layouts/images/verification.gif'
import axios from 'axios';

function PassagerSendInBackendStep1() {
    const { token } = useParams();
    const [licenseNumber, setLicenseNumber] = useState('');
    const [reg_certificate_number, setReg_certificate_number] = useState('');
    const [data, setData] = useState();
    const [licenseImage, setLicenseImage] = useState(null);
    const [licenseImageBack, setLicenseImageBack] = useState(null);
    const [regCertificateImage, setRegCertificateImage] = useState(null);
    const [regCertificateImageBack, setRegCertificateImageBack] = useState(null);
    const [licenseExpiredDate, setLicenseExpiredDate] = useState('');
    const [step, setStep] = useState(1);
    const [selectedCarId, setSelectedCarId] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleLicenseImageDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setLicenseImage(acceptedFiles[0]);
        }
    };
    
    const handleLicenseImageBackDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setLicenseImageBack(acceptedFiles[0]);
        }
    };

    const handleRegCertificateImageDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setRegCertificateImage(acceptedFiles[0]);
        }
    };

    const handleRegCertificateImageBackDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setRegCertificateImageBack(acceptedFiles[0]);
        }
    };

    const isStep1Valid = () => {
        return licenseNumber && licenseImage && licenseImageBack && licenseExpiredDate;
    };


    const handleNextButtonClick = () => {
        if (isStep1Valid()) {
            setStep(2);
        } else {
            alert('Iltimos, barcha maydonlarni to\'ldiring');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("http://api.easygo.uz/api/car/driver-car", {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "language": "ru"
                    }
                });
                setData(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.log('error', error);
            }
        };

        fetchData();
    }, []);

    console.log(selectedCarId);

    const handleSendButtonClick = async () => {
        if (licenseImage && licenseImageBack && regCertificateImage && regCertificateImageBack) {
            try {
                setSubmitStatus('pending');
                
                const formdata = new FormData();
                formdata.append("car_id", selectedCarId);
                formdata.append("license_number", licenseNumber);
                formdata.append("reg_certificate_number", reg_certificate_number);
                formdata.append("license_expired_date", licenseExpiredDate);
                formdata.append("license_image", licenseImage);
                formdata.append("license_image_back", licenseImageBack);
                formdata.append("reg_certificate_image", regCertificateImage);
                formdata.append("reg_certificate_image_back", regCertificateImageBack);

                const requestOptions = {
                    method: 'POST',
                    body: formdata,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "language": "ru"
                    }
                };

                const response = await fetch("http://api.easygo.uz/api/driver-accept", requestOptions);
                const result = await response.text();

                console.log(result);

                if (response.ok) {
                    setSubmitStatus('success');
                } else {
                    setSubmitStatus('error');
                }
            } catch (error) {
                setSubmitStatus('error');
                console.log('error', error);
            }
        }
    };

    console.log(data);

    return (
        <form onSubmit={(e) => { e.preventDefault(); handleSendButtonClick(); }} style={{ padding: '10px' }} method="post" encType="multipart/form-data" action="http://api.easygo.uz/api/driver-accept" >
            {step === 1 && (
                <div className="step1">
                    <div className='d-flex mb-5' style={{width: '100%', textAlign: 'center'}}>
                        <p className='headerText2'>ВОДИТЕЛЬСКОЕ УДОСТОВЕРЕНИЕ</p>
                    </div>

                    <label style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <p className='mobile_text' style={{fontSize: '18px'}}>Номер водительского удостоверения</p>
                        <input
                            style={{borderRadius: '10px', border: '1.5px solid rgb(122 125 127 / 30%)', width: '100%', height: '47px', marginTop: '-10px'}}
                            type="text"
                            value={licenseNumber}
                            onChange={(e) => setLicenseNumber(e.target.value)}
                        />
                    </label>

                    <label htmlFor='fileInputFront' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
                        <Dropzone onDrop={handleLicenseImageDrop} accept=".jpg,.jpeg,.png">
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                                    <img style={{ width: '200px', height: '200px', objectFit: 'contain', cursor: 'pointer' }} src={licenseImage ? URL.createObjectURL(licenseImage) : Front} alt="Front" />
                                    <div style={{ width: '100%', textAlign: 'center', borderRadius: '30px', height: '60px', marginTop: '10px', paddingTop: '10px', background: 'linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%)', color: 'white', fontSize: '25px', border: 'none' }}>Добавить фото</div>
                                    <input {...getInputProps()} style={{ display: 'none' }} />
                                    {licenseImage && <p>Выбранный файл: {licenseImage.name}</p>}
                                </div>
                            )}
                        </Dropzone>
                    </label>

                    <label htmlFor='fileInputBack' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
                        <Dropzone onDrop={handleLicenseImageBackDrop} accept=".jpg,.jpeg,.png">
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                                    <img style={{ width: '200px', height: '200px', objectFit: 'contain', cursor: 'pointer' }} src={licenseImageBack ? URL.createObjectURL(licenseImageBack) : Back} alt="Back" />
                                    <div style={{ width: '100%', textAlign: 'center', borderRadius: '30px', height: '60px', marginTop: '10px', paddingTop: '10px', background: 'linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%)', color: 'white', fontSize: '25px', border: 'none' }}>Добавить фото</div>
                                    <input {...getInputProps()} style={{ display: 'none' }} />
                                    {licenseImageBack && <p>Выбранный файл: {licenseImageBack.name}</p>}
                                </div>
                            )}
                        </Dropzone>
                    </label>

                    <label style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '50px'}}>
                        <p className='mobile_text' style={{fontSize: '18px'}}>Дата истечения срока действия</p>
                        <input
                            style={{borderRadius: '10px', border: '1.5px solid rgb(122 125 127 / 30%)', width: '100% !important', height: '47px', marginTop: '-10px'}}
                            type="date"
                            value={licenseExpiredDate}
                            onChange={(e) => setLicenseExpiredDate(e.target.value)}
                        />
                    </label>

                    <button
                        className='d-flex justify-content-center align-items-center'
                        onClick={handleNextButtonClick}
                        style={{
                            width: '100%',
                            textDecoration: 'none',
                            borderRadius: '30px',
                            height: '60px',
                            marginTop: '30px',
                            background: 'linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%)',
                            color: 'white',
                            fontSize: '25px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Далее
                    </button>
                </div>
            )}

            {step === 2 && submitStatus !== 'success' && submitStatus !== 'error' &&  (
                <div className="step2" style={{display: (step === 2 && submitStatus === 'success') ? 'none' : 'block'}}>
                    <div className='d-flex mb-5' style={{marginLeft: '20px'}}>
                        <button onClick={() => setStep(1)} style={{ fontFamily: 'Jaldi', background: 'none', border: 'none', fontSize: '50px', marginTop: '-18px' }}>
                            <svg style={{ width: '20px', height: '20px' }} onClick={() => setStep(1)} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width={30} height={30} viewBox="0 0 256 256" xmlSpace="preserve">
                                <defs></defs>
                                <g style={{ stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1 }} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                    <path
                                        d="M 65.75 90 c 0.896 0 1.792 -0.342 2.475 -1.025 c 1.367 -1.366 1.367 -3.583 0 -4.949 L 29.2 45 L 68.225 5.975 c 1.367 -1.367 1.367 -3.583 0 -4.95 c -1.367 -1.366 -3.583 -1.366 -4.95 0 l -41.5 41.5 c -1.367 1.366 -1.367 3.583 0 4.949 l 41.5 41.5 C 63.958 89.658 64.854 90 65.75 90 z"
                                        style={{ stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1 }}
                                        transform=" matrix(1 0 0 1 0 0) "
                                        strokeLinecap="round"
                                    />
                                </g>
                            </svg>
                        </button>
                        <p onClick={() => setStep(1)} className='headerText' style={{marginLeft: '-60px', cursor: 'pointer'}}>ДАННЫЕ О ТРАНСПОРТЕ</p>
                    </div>

                    <label style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <p className='mobile_text' style={{fontSize: '18px'}}>Номер водительского удостоверения</p>
                        <input
                            style={{borderRadius: '10px', border: '1.5px solid rgb(122 125 127 / 30%)', width: '100%', height: '47px', marginTop: '-10px'}}
                            type="text"
                            value={reg_certificate_number}
                            onChange={(e) => setReg_certificate_number(e.target.value)}
                        />
                    </label>

                    <label style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className='mobile_text' style={{ fontSize: '18px' }}>Tanlangan data</p>
                        <select
                            style={{ borderRadius: '10px', border: '1.5px solid rgb(122 125 127 / 30%)', width: '100%', height: '47px', marginTop: '-10px' }}
                            value={selectedCarId}
                            onChange={(e) => setSelectedCarId(e.target.value)}
                        >
                            <option selected>Tanlash uchun</option>
                            {data.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.car_name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', backgroundColor: '#d1d1d1', borderRadius: '20px', height: '340px', marginTop: '40px'}}>
                        <p style={{marginLeft: '0px'}} className='headerText mt-5'>Cвидетельство о регистрации ТС<br />(лицевая сторона)</p>

                        <Dropzone onDrop={handleRegCertificateImageDrop} accept=".jpg,.jpeg,.png">
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                                    <img style={{ width: '200px', height: '200px', objectFit: 'contain', cursor: 'pointer' }} src={regCertificateImage ? URL.createObjectURL(regCertificateImage) : Front2} alt="Front" />
                                    <div style={{ width: '100%', borderRadius: '30px', paddingTop: '10px', height: '60px', marginTop: '10px', background: 'linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%)', color: 'white', fontSize: '25px', border: 'none' }}>Добавить фото</div>
                                    <input {...getInputProps()} style={{ display: 'none' }} />
                                    {regCertificateImage && <p>Выбранный файл: {regCertificateImage.name}</p>}
                                </div>
                            )}
                        </Dropzone>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', backgroundColor: '#d1d1d1', borderRadius: '20px', height: '340px', marginTop: '40px'}}>
                        <p style={{marginLeft: '0px'}} className='headerText mt-5'>Cвидетельство о регистрации ТС <br /> (обратная сторона)</p>

                        <Dropzone onDrop={handleRegCertificateImageBackDrop} accept=".jpg,.jpeg,.png">
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                                    <img style={{ width: '200px', height: '200px', objectFit: 'contain', cursor: 'pointer' }} src={regCertificateImageBack ? URL.createObjectURL(regCertificateImageBack) : Back2} alt="Back" />
                                    <div style={{ width: '100%', borderRadius: '30px', paddingTop: '10px', height: '60px', marginTop: '10px', background: 'linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%)', color: 'white', fontSize: '25px', border: 'none' }}>Добавить фото</div>
                                    <input {...getInputProps()} style={{ display: 'none' }} />
                                    {regCertificateImageBack && <p>Выбранный файл: {regCertificateImageBack.name}</p>}
                                </div>
                            )}
                        </Dropzone>
                    </div>

                    <button onClick={handleSendButtonClick} style={{width: '100%', borderRadius: '30px', height: '60px', marginTop: '60px', background: 'linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%)', color: 'white', fontSize: '25px', border: 'none'}}>{submitStatus === 'pending' ? 'Отправка...' : 'Отправить'}</button>
                </div>
            )}

            {submitStatus === 'success' && (
                <div className="success" style={{border: '1px solid blue'}}>
                    <img style={{ width: '300px', height: '300px', borderRadius: '20px', marginLeft: '70px', marginTop: '20px' }} src={Verifed} alt="Success" />
                    <h1 style={{ textAlign: 'center', marginTop: '30px', padding: '50px' }}>Ваши данные успешно отправлены.В ближайшее время Наши операторы свяжутся с вами.</h1>
                </div>
            )}

            {submitStatus === 'error' && (
                <div className="error">
                    <img style={{ width: '300px', height: '300px', borderRadius: '20px', marginLeft: '50px', marginTop: '20px' }} src={Error} alt="Error" />
                    <h2 style={{ textAlign: 'center', marginTop: '30px', padding: '50px' }}>Произошла ошибка. Пожалуйста, попробуйте еще раз. Или вернитесь на <span onClick={() => setStep(1)} style={{ cursor: 'pointer', color: 'rgb(12,205,224)' }}>первую страницу</span>, чтобы убедиться, что вы все заполнили, и отправьте заявку повторно.</h2>
                </div>
            )}
        </form>
    );
}

export default PassagerSendInBackendStep1;
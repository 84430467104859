import React from 'react'
import { useNavigate } from 'react-router-dom'

function PassagerAccesCencelBrone() {
  const navigate = useNavigate()

  return (
    <div style={{ padding: '10px' }}>
      <div className='d-flex mb-5' style={{marginLeft: '-20px'}}>
        <button onClick={() => navigate(-1)} style={{ fontFamily: 'Jaldi', background: 'none', border: 'none', fontSize: '50px', marginTop: '-18px' }}>
          <svg style={{ width: '20px', height: '20px' }} onClick={() => navigate(-1)} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width={30} height={30} viewBox="0 0 256 256" xmlSpace="preserve">
            <defs></defs>
            <g style={{ stroke: "none", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "none", fillRule: "nonzero", opacity: 1 }} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
              <path
                d="M 65.75 90 c 0.896 0 1.792 -0.342 2.475 -1.025 c 1.367 -1.366 1.367 -3.583 0 -4.949 L 29.2 45 L 68.225 5.975 c 1.367 -1.367 1.367 -3.583 0 -4.95 c -1.367 -1.366 -3.583 -1.366 -4.95 0 l -41.5 41.5 c -1.367 1.366 -1.367 3.583 0 4.949 l 41.5 41.5 C 63.958 89.658 64.854 90 65.75 90 z"
                style={{ stroke: "none", strokeWidth: 1, strokeDasharray: "none", strokeLinecap: "butt", strokeLinejoin: "miter", strokeMiterlimit: 10, fill: "rgb(0,0,0)", fillRule: "nonzero", opacity: 1 }}
                transform=" matrix(1 0 0 1 0 0) "
                strokeLinecap="round"
              />
            </g>
          </svg>
        </button>
        <p className='headerText'>Как отменить бронирование?</p>
      </div>

      <p className='mobile_text p-1'>
        Если вы столкнулись с ситуацией, в которой вам нужно отменить вашу поездке, постарайтесь сразу же отменить бронирование и предупредить водителя позвонив ему лично. Водитель будет благодарен вам, а ещё он сможет быстрее найти другого пассажира на освободившееся место. <br />
        Чтобы отменить бронирование: <br />
        &nbsp; 1. Зайдите в "Мои поездки" на странице "Я <br />
        Пассажир"; <br />
        &nbsp; 2. Откройте публикацию поездки и нажмите <br />
        "Отменить бронь' <br />
        Наша платформа уведомит водителя о вашем решении.
      </p>
    </div>
  )
}

export default PassagerAccesCencelBrone